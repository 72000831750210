import React, { useState, useEffect } from "react";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../utils/firebase";
import { Box, Typography, Button, Grid } from "@mui/material";
import { LinearGradient } from "react-text-gradients";
import { motion } from "framer-motion";
import { useMediaQuery } from "react-responsive";
import HeroCards from "../components/Cards/HeroCards";
import HeroArt from "../components/HeroArt";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CampaignIcon from "@mui/icons-material/Campaign";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import Dashboard from "../components/Dashboard/Dashboard";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";

const Home = () => {
  const [tiktokFollowers, setTiktokFollowers] = useState(0);
  const [tiktokBudget, setTiktokBudget] = useState(0);
  const [linkedinFollowers, setLinkedinFollowers] = useState(0);
  const [linkedinBudget, setLinkedinBudget] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch TikTok data
        const tiktokDoc = await getDoc(doc(db, "homedata", "tiktok"));
        if (tiktokDoc.exists()) {
          setTiktokFollowers(tiktokDoc.data().followers);
          setTiktokBudget(tiktokDoc.data().budget);
        }

        // Fetch LinkedIn data
        const linkedinDoc = await getDoc(doc(db, "homedata", "linkedin"));
        if (linkedinDoc.exists()) {
          setLinkedinFollowers(linkedinDoc.data().followers);
          setLinkedinBudget(linkedinDoc.data().budget);
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchData();
  }, []);

  const [hasCounted, setHasCounted] = useState(false);
  const items = [
    {
      id: "1",
      title: "Create Account",
      subtitle: "Easy registration",
      description: "Create an account and link your social media handles. Add your interests and favorite brands, build out your profile, and search for campaigns!",
      icon: <AccountCircleIcon fontSize="large" color="primary" />,
    },
    {
      id: "2",
      title: "Participate in campaigns!",
      subtitle: "Support your favorites",
      description: "Automatically join, apply, or be invited to various campaigns hosted by your favorite companies. You will know which campaigns you are eligible for, or get invited to.",
      icon: <CampaignIcon fontSize="large" color="primary" />,
    },
    {
      id: "3",
      title: "Get paid",
      subtitle: "Benefit from participation",
      description: "By participating in campaigns and meeting the requirements, you earn cash and other rewards. Incentives are customizable on the company's behalf.",
      icon: <CardGiftcardIcon fontSize="large" color="primary" />,
    },
  ];

  const isTablet = useMediaQuery({ query: "(max-width: 768px)" });

  return (
    <>
      <Box sx={{ padding: "1rem", display: "flex", alignItems: "center", justifyContent: "space-around", flexDirection: `${isTablet ? "column" : "row"}`, gap: 2, mb: "120px" }}>
        <Box sx={{ textAlign: isTablet ? "center" : "", display: "flex", flexDirection: "column", alignItems: isTablet ? "center" : "", justifyContent: isTablet ? "center" : "" }}>
          <Typography variant="h3" sx={{ lineHeight: "95%", my: "10px", fontWeight: "bold" }}>
            Democratizing<br></br>
            <Typography
              component="span"
              variant="h3"
              sx={{
                background: "linear-gradient(to left, #1976d2, #20c997)",
                backgroundClip: "text",
                WebkitBackgroundClip: "text",
                color: "transparent",
                animation: "colorChange 5s infinite",
                "@keyframes colorChange": {
                  "0%": { filter: "hue-rotate(0deg)" },
                  "100%": { filter: "hue-rotate(360deg)" },
                },
                fontWeight: "bold",
                lineHeight: "95%",
              }}>
              Influence
            </Typography>
          </Typography>
          <Typography
            variant="body1"
            sx={{
              color: "gray",
              maxWidth: "500px",
              marginBottom: "8px",
              fontWeight: 400,
              fontSize: "1.1rem", // This makes it 25% bigger
            }}>
            Reach is a freelance marketing platform changing the way brands and consumers interact. Create large-scale social media ambassador campaigns made up of thousands of your most loyal
            supporters.
          </Typography>

          <Box sx={{ display: "flex", gap: 2, padding: 0 }}>
            <Button
              variant="outlined"
              href="/about"
              sx={{
                borderRadius: "28px",
                borderColor: "black",
                color: "black",
                "&:hover": {
                  borderColor: "black",
                  animation: "bounce 0.5s",
                  "@keyframes bounce": {
                    "0%, 100%": { transform: "translateY(0)" },
                    "50%": { transform: "translateY(-5px)" },
                  },
                  backgroundColor: "transparent",
                },
              }}>
              More information
            </Button>

            <Button
              variant="contained"
              href="/waitlist"
              sx={{
                borderRadius: "28px",
                backgroundColor: "black",
                color: "white",
                "&:hover": {
                  backgroundColor: "black",
                  animation: "bounce 0.5s",
                  "@keyframes bounce": {
                    "0%, 100%": { transform: "translateY(0)" },
                    "50%": { transform: "translateY(-5px)" },
                  },
                },
              }}>
              Join the waitlist
            </Button>
          </Box>
        </Box>
        {!isTablet && (
          <Box sx={{ maxHeight: "500px", width: "500px" }}>
            <HeroArt style={{ height: "100%", width: "100%" }} />
          </Box>
        )}
      </Box>

      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", padding: "1rem", mb: "120px" }}>
        <Typography variant="h4" sx={{ fontWeight: "bold", lineHeight: "95%", my: "10px", textAlign: "center", mb: "30px" }}>
          What is <LinearGradient gradient={["to left", "#20c997 ,#1976d2"]}>Reach?</LinearGradient>
        </Typography>
        <Box>
          <HeroCards />
        </Box>
      </Box>

      <Box id="about" sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", padding: "1rem", mb: "120px" }}>
        <Typography variant="h4" sx={{ fontWeight: "bold", lineHeight: "95%", my: "10px", textAlign: "center", mb: "30px" }}>
          How does it <LinearGradient gradient={["to left", "#20c997 ,#1976d2"]}>work?</LinearGradient>
        </Typography>
        <Box>
          <Grid container spacing={4} justifyContent="center">
            {items.map((item) => (
              <Grid item key={item.id} xs={12} sm={6} md={4}>
                <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
                  <Box elevation={3} sx={{ backgroundColor: "#cde5f9", padding: "40px 20px", borderRadius: "8px" }}>
                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          backgroundColor: "white",
                          width: "fit-content",
                          padding: ".5rem",
                          height: "fit-content",
                          borderRadius: "100%",
                          mb: "1rem",
                        }}>
                        {item.icon}
                      </Box>
                      <Typography variant="h5" component="h2" color="primary" align="center">
                        {item.title}
                      </Typography>

                      <Typography variant="body1" sx={{ color: "gray" }} align="center">
                        {item.description}
                      </Typography>
                    </Box>
                  </Box>
                </motion.div>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>

      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", padding: "1rem", mb: "120px" }}>
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", gap: 2 }}>
          <HourglassEmptyIcon fontSize="large" color="primary" />
          <Typography variant="h4" sx={{ fontWeight: "bold", lineHeight: "95%", textAlign: "center" }}>
            Coming soon...
          </Typography>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
          <Typography variant="body1" sx={{ color: "gray", maxWidth: "550px", marginTop: "20px", textAlign: "center", fontSize: "1.1rem" }}>
            To test our theory we will be using our technology to grow. We want YOU to be a part of it. We will begin hosting campaigns that are exclusively open to our waitlist users, and we will pay
            our first supporters between $3-$10 to post our content on their Instagram stories.
          </Typography>
          <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
            <Button
              variant="contained"
              href="/waitlist"
              sx={{
                marginTop: "20px",
                backgroundColor: "black",
                color: "white",
                borderRadius: "20px",
                "&:hover": {
                  backgroundColor: "#333",
                },
              }}>
              Join the waitlist to be considered!
            </Button>
          </motion.div>
        </Box>
      </Box>

      <Typography variant="h4" sx={{ fontWeight: "bold", lineHeight: "95%", my: "10px", textAlign: "center", mb: "30px" }}>
        First <LinearGradient gradient={["to left", "#20c997 ,#1976d2"]}>campaign</LinearGradient> tracker:
      </Typography>

      <Grid container rowSpacing={1} columnSpacing={1} sx={{ mb: "120px" }}>
        <Grid item xs={12} sm={6} md={3} sx={{ border: "1.5px solid #e0e0e0", borderRadius: "1rem", p: 1 }}>
          <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
              <Typography variant="body1" sx={{ color: "gray" }}>
                TikTok Followers
              </Typography>
              <Typography variant="h4" sx={{ fontWeight: "bold", lineHeight: "95%" }}>
                <VisibilitySensor active={!hasCounted}>{({ isVisible }) => <CountUp end={isVisible ? tiktokFollowers : 0} duration={5} />}</VisibilitySensor>
              </Typography>
            </Box>
          </motion.div>
        </Grid>

        <Grid item xs={12} sm={6} md={3} sx={{ border: "1.5px solid #e0e0e0", borderRadius: "1rem", p: 1 }}>
          <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
              <Typography variant="body1" sx={{ color: "gray" }}>
                TikTok Budget
              </Typography>
              <Typography variant="h4" sx={{ fontWeight: "bold", lineHeight: "95%" }}>
                $<VisibilitySensor active={!hasCounted}>{({ isVisible }) => <CountUp end={isVisible ? tiktokBudget : 0} duration={5} />}</VisibilitySensor>
              </Typography>
            </Box>
          </motion.div>
        </Grid>

        <Grid item xs={12} sm={6} md={3} sx={{ border: "1.5px solid #e0e0e0", borderRadius: "1rem", p: 1 }}>
          <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
              <Typography variant="body1" sx={{ color: "gray" }}>
                LinkedIn Followers
              </Typography>
              <Typography variant="h4" sx={{ fontWeight: "bold", lineHeight: "95%" }}>
                <VisibilitySensor active={!hasCounted}>{({ isVisible }) => <CountUp end={isVisible ? linkedinFollowers : 0} duration={5} />}</VisibilitySensor>
              </Typography>
            </Box>
          </motion.div>
        </Grid>

        <Grid item xs={12} sm={6} md={3} sx={{ border: "1.5px solid #e0e0e0", borderRadius: "1rem", p: 1 }}>
          <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
              <Typography variant="body1" sx={{ color: "gray" }}>
                LinkedIn Budget
              </Typography>
              <Typography variant="h4" sx={{ fontWeight: "bold", lineHeight: "95%" }}>
                $
                <VisibilitySensor
                  active={!hasCounted}
                  onChange={(isVisible) => {
                    if (isVisible) {
                      setHasCounted(true);
                    }
                  }}>
                  {({ isVisible }) => <CountUp end={isVisible ? linkedinBudget : 0} duration={5} />}
                </VisibilitySensor>
              </Typography>
            </Box>
          </motion.div>
        </Grid>
      </Grid>

      <Typography variant="h4" sx={{ fontWeight: "bold", lineHeight: "95%", my: "10px", textAlign: "center", mb: "30px" }}>
        Detailed <LinearGradient gradient={["to left", "#20c997 ,#1976d2"]}>analytics</LinearGradient> on your campaigns:
      </Typography>
      <Dashboard />
      <Box sx={{ mb: "120px" }}></Box>
    </>
  );
};

export default Home;
